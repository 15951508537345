<template>
  <div class="d-flex flex-column align-items-center app-wrapper">
    <div v-if="evina === false" :class="`${this.backgroundStyle} col-12 col-sm-8 col-md-6 col-lg-5 col-xl-4`">
      <img v-if="burgerMenuIconVisible" src="./assets/hamburger-menu.png" class="menu-icon"
           @click="toggleMenuVisibility"/>
      <transition name="slide-burger-menu">
        <div class="menu-container" v-if="burgerMenuVisible">
          <img class="close-icon" src="./assets/btn-popup-closing-button.png" @click="toggleMenuVisibility">
          <div class="d-flex flex-column align-items-center col-12 my-2">
            <img class="game-logo" :src="require(`./brand/${brandConfig.brand}/assets/game-logo.png`)"/>
          </div>
          <div class="d-flex flex-column justify-content-center col-12">
            <div class="d-flex flex-column col-12 justify-content-center align-items-center mb-4">
              <div class="d-flex flex-column align-items-center profile-block py-3">
                <img @click="onEditUsernameClicked" src="./assets/edit-icon.png" class="edit-profile-icon"/>
                <img src="./assets/avatars/9.png" class="profile-icon mb-3"/>
                <span>{{ user.username }}</span>
              </div>
            </div>
            <div class="d-flex row mb-4" @click="onHomeClicked()">
              <div class="d-flex flex-column align-items-end col-3 justify-content-center">
                <img src="./assets/home-active.png" class="menu-item-icon"/>
              </div>
              <span class="col-8 d-flex flex-column justify-content-center">Home</span>
            </div>
            <div class="d-flex row mb-4" @click="onProfileClicked()">
              <div class="d-flex flex-column align-items-end col-3 justify-content-center">
                <img src="./assets/profile-icon-active.png" class="menu-item-icon"/>
              </div>
              <span class="col-8 d-flex flex-column justify-content-center">Profile</span>
            </div>
            <div class="d-flex row mb-4" @click="onManageSubscriptionClicked()">
              <div class="d-flex flex-column align-items-end col-3 justify-content-center">
                <img src="./assets/manage-sub-active.png" class="menu-item-icon"/>
              </div>
              <span class="col-8 d-flex flex-column justify-content-center">Manage Subscription</span>
            </div>
            <div class="d-flex row mb-4" @click="onHowToPlayClicked()">
              <div class="d-flex flex-column align-items-end col-3 justify-content-center">
                <img src="./assets/how-to-play-active.png" class="menu-item-icon"/>
              </div>
              <span class="col-8 d-flex flex-column justify-content-center">How to Play</span>
            </div>
            <div class="d-flex row mb-4" @click="onMyFriendsClicked()">
              <div class="d-flex flex-column align-items-end col-3 justify-content-center">
                <img src="./assets/my-friends-active.png" class="menu-item-icon"/>
              </div>
              <span class="col-8 d-flex flex-column justify-content-center">My Friends</span>
            </div>
            <div class="d-flex row mb-4" @click="onTermsAndConditionsClicked()">
              <div class="d-flex flex-column align-items-end col-3 justify-content-center">
                <img src="./assets/terms-conditions-active.png" class="menu-item-icon"/>
              </div>
              <span class="col-8 d-flex flex-column justify-content-center">Terms & Conditions</span>
            </div>
            <div class="d-flex row mb-4" @click="onRewardsClicked()">
              <div class="d-flex flex-column align-items-end col-3 justify-content-center">
                <img src="./assets/reward-active.png" class="menu-item-icon"/>
              </div>
              <span class="col-8 d-flex flex-column justify-content-center">Rewards</span>
            </div>
          </div>
        </div>
      </transition>
      <LoadingOverlay v-if="this.isLoading"/>
      <div v-if="showHowToPlayPopup" class="pop-up-wrapper">
        <div class="pop-up p-4">
          <img @click="onHowToPlayPopupCloseClicked()" src="./assets/btn-popup-closing-button.png"
               class="pop-up-close-button btn"/>
          <div class="d-flex flex-column align-items-center" v-if="howItWorksStep === 1">
            <img src="./assets/how-it-works-title-1.png" class="pop-up-title"/>
            <img src="./assets/how-it-works-icon-1.png" class="pop-up-icon"/>
            <span class="body-text mb-2">Play Quizinc Head 2 Head for Free & you'll receive 5 coins (once off).</span>
            <span class="body-text mb-2">Spend 1 coin to play and receive that coin back if you win. If you lose you won't receive any coins for that game.</span>
            <span class="body-text mb-2">No coins? Subscribe to VIP and receive 10 coins plus more when you win!</span>
            <span class="body-text mb-2">Once subbed, winning gives you an extra coin. If you run out of coins, you can wait for the next day's 5 coins.</span>
          </div>
          <div class="d-flex flex-column align-items-center" v-if="howItWorksStep === 2">
            <img src="./assets/how-it-works-title-2.png" class="pop-up-title"/>
            <img src="./assets/how-it-works-icon-2.png" class="pop-up-icon"/>
            <span class="body-text mb-2">You and your opponent answer the same 10 questions.</span>
            <span class="body-text mb-2">The person with the most correct answers wins!</span>
            <span class="body-text mb-2">So outsmart your opponent by getting the highest score. If you both have the same score, you’ll both get your coins back.</span>
            <span class="body-text mb-2">As VIP, you can challenge your friends! Invite a friend to get 20 coins when they subscribe (limited to 10 friend invites).</span>
          </div>
          <div class="d-flex flex-column align-items-center" v-if="howItWorksStep === 3">
            <img src="./assets/how-it-works-title-3.png" class="pop-up-title"/>
            <img src="./assets/how-it-works-icon-3.png" class="pop-up-icon"/>
            <span class="body-text mb-2">The more you play the higher your chances of being a lucky draw winner daily and standing a chance to win your share of R175 000 in prizes.</span>
            <span class="body-text mb-2">Daily winners are chosen at 12pm every day, and awarded a R200 voucher, R25 or R10 airtime.</span>
          </div>
          <div class="d-flex row">
            <div class="col-6 d-flex flex-column pr-2 justify-content-end">
              <img src="./assets/btn-back-arrow.png" v-if="howItWorksStep > 1" @click="onHowItWorksBackArrowClicked"
                   class="arrow-icon"/>
            </div>
            <div class="col-6 d-flex flex-column pl-2 justify-content-end">
              <img src="./assets/btn-next-arrow.png" v-if="howItWorksStep < 3" @click="onHowItWorksNextArrowClicked"
                   class="arrow-icon"/>
            </div>
          </div>
        </div>
      </div>
      <div v-if="showPendingChallengeRequests" class="pop-up-wrapper">
        <div class="pop-up p-3">
          <img @click="onClosePendingChallengeRequestsClicked()" src="./assets/btn-popup-closing-button.png"
               class="pop-up-close-button btn"/>
          <div class="d-flex flex-column align-items-center col-12">
            <img src="./assets/how-it-works-icon-2.png" class="pop-up-icon"/>
            <img src="./assets/you-have-been-challenged.png" class="pop-up-title"/>
            <div class="pending-challenge-request-table table-responsive mt-4 mb-2 col-12">
              <table class="table pending-challenge-request-table">
                <thead>
                <tr>
                  <th scope="col">User</th>
                  <th class="text-center" scope="col">Action</th>
                </tr>
                </thead>
                <tbody>
                <tr v-if="pendingChallengeRequests.length > 0"
                    v-for="pendingChallengeRequest in pendingChallengeRequests" :key="pendingChallengeRequest.msisdn">
                  <td>{{ pendingChallengeRequest.username }}</td>
                  <td>
                    <div class="d-flex flex-nowrap justify-content-center">
                      <img @click="onRespondToPendingChallengeRequestClicked(pendingChallengeRequest)"
                           class="request-option confirm-btn" src="./assets/confirm-btn-blue.png"/>
                    </div>
                  </td>
                </tr>
                <tr v-if="pendingChallengeRequests.length === 0">
                  <td colspan="2"><span class="text-white body-text">You have no pending challenge requests.</span></td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <div v-if="showInsufficientFunds" class="pop-up-wrapper">
        <div class="pop-up p-4">
          <img @click="onInsufficientFundsCloseClicked()" src="./assets/btn-popup-closing-button.png"
               class="pop-up-close-button btn"/>
          <div class="d-flex flex-column align-items-center">
            <img src="./assets/out-of-coins-pop-up-title-1.png" class="pop-up-title"/>
            <span
                class="body-text mb-2">If you are a Free to play user, you will need to subscribe to gain more coins.</span>
            <span class="body-text mb-2">If you are a Premium user, you will be allocated more coins on your next successful billing event!</span>
          </div>
        </div>
      </div>
      <router-view class="mt-3 px-2"/>
    </div>
    <div v-if="evina === true">
      <router-view class="px-2"/>
    </div>
  </div>
</template>

<script>
import LoadingOverlay from "@/views/components/LoadingOverlay";
import {mapState, mapMutations} from "vuex";
import {setBrandConfig} from "@/functions/setBrandConfig";
import MultiplayerTriviaService from "@/services/multiplayerTriviaService";
import {Brands} from "@/brand/brandConfig";
import DcbService from "@/services/dcbService";

export default {
  components: {LoadingOverlay},
  computed: {
    ...mapState(['isLoading', 'user', 'brandConfig', 'burgerMenuIconVisible', 'showHowToPlayPopup', 'showPendingChallengeRequests', 'pendingChallengeRequests', 'showInsufficientFunds'])
  },
  data() {
    return {
      burgerMenuVisible: false,
      howItWorksStep: 1,
      backgroundStyle: `app-container`,
      evina: undefined,
      encryptedMsisdn: undefined,
    }
  },
  methods: {
    ...mapMutations(['setShowHowToPlayPopup', 'setShowPendingChallengeRequests', 'setShowInsufficientFunds']),
    toggleMenuVisibility() {
      this.burgerMenuVisible = !this.burgerMenuVisible;
    },
    setFavicon() {
      const favicon = document.getElementById("favicon");
      favicon.href = require(`./brand/${this.brandConfig.brand}/assets/game-logo.png`)
    },
    setTabName() {
      document.title = this.brandConfig.gameTitle;
    },
    onHomeClicked() {
      this.toggleMenuVisibility();
      this.$router.push('/landing');
    },
    onProfileClicked() {
      if (this.burgerMenuVisible) this.toggleMenuVisibility();
      this.$router.push(`/profile`);
    },
    onManageSubscriptionClicked() {
      if (this.burgerMenuVisible) this.toggleMenuVisibility();
      this.$router.push(`/manage-subscription`);
    },
    async encryptMsisdn() {
      let encryptMsisdnResponse = (await DcbService.encryptUserMsisdn(this.user.msisdn)).data;
      this.encryptedMsisdn = encryptMsisdnResponse.encryptedMsisdn;
    },
    async viewRewardClicked() {
      await this.encryptMsisdn();
      window.open(`https://voucher-store.quizinc.co.za/?e-msisdn=${this.encryptedMsisdn}&productId=${this.brandConfig.productId}`, '_self');
    },
    onInsufficientFundsCloseClicked() {
      this.setShowInsufficientFunds(false);
    },
    onHowToPlayClicked() {
      // if (this.burgerMenuVisible) this.toggleMenuVisibility();
      this.setShowHowToPlayPopup(true);
    },
    onMyFriendsClicked() {
      if (this.burgerMenuVisible) this.toggleMenuVisibility();
      this.$router.push('/my-friends');
    },
    async onRespondToPendingChallengeRequestClicked(pendingChallengeRequest) {
      const challengeRequestResponse = await MultiplayerTriviaService.sendChallengeRequest(this.user.msisdn, this.user.username, pendingChallengeRequest.opponentMsisdn, 0, 1);
      this.setShowPendingChallengeRequests(false);
    },
    onTermsAndConditionsClicked() {
      window.open(`https://termsandconditions.quizinc.co.za/`);
    },
    onHowToPlayPopupCloseClicked() {
      this.howItWorksStep = 1;
      this.setShowHowToPlayPopup(false);
    },
    onHowItWorksBackArrowClicked() {
      this.howItWorksStep--;
    },
    onHowItWorksNextArrowClicked() {
      this.howItWorksStep++;
    },
    onEditUsernameClicked() {
      if (this.burgerMenuVisible) this.toggleMenuVisibility();
      this.$router.push(`/create-username`);
    },
    onClosePendingChallengeRequestsClicked() {
      this.setShowPendingChallengeRequests(false);
    },
    checkEvina() {
      const evinaBrands = [
        Brands.VodacomEvinaSouthAfricaEnglish,
      ];
      this.evina = evinaBrands.includes(this.brandConfig.brand);
    },
    injectTealium() {
      (function (a, b, c, d) {
        a = 'https://tags.tiqcdn.com/utag/vodafone/za-quizinc-main/prod/utag.js';
        b = document;
        c = 'script';
        d = b.createElement(c);
        d.src = a;
        d.type = 'text/java' + c;
        d.async = true;
        a = b.getElementsByTagName(c)[0];
        a.parentNode.insertBefore(d, a);
      })();
    }
  },
  beforeMount() {
    setBrandConfig();
    this.checkEvina();
    this.setFavicon();
    this.setTabName();
    this.injectTealium();
  },
  watch: {
    $route(to, from) {
      if (this.$route.path === '/doi-two' && this.brandConfig.brand === Brands.VodacomEvinaSouthAfricaEnglish) {
        this.backgroundStyle = `app-container-encrypted`
      }
    }
  }
}
</script>

<style>
.app-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow-y: scroll;
  position: relative;
  background-image: url("./assets/background.webp");
  background-size: cover;
}

.app-container-encrypted {
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow-y: scroll;
  position: relative;
  background-color: #1c1c1c;
  background-size: cover;
}

.menu-icon {
  position: absolute;
  left: 0.3rem;
  top: 0.3rem;
  height: 40px;
  width: 40px;
}

::-webkit-scrollbar {
  display: none;
}

.burger-menu-image {
  position: absolute;
}

.image-default-container {
  position: relative;
  height: 88%;
}

.table {
  background-color: grey;
}

td {
  background-color: black !important;
  color: #FFFFFF !important;
}

th {
  background-color: black !important;
  color: #FFFFFF !important;
}

.app-wrapper {
  position: relative;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  /*background-image: url("./assets/background.png");*/
  background-color: #000000;
  background-size: cover;
  z-index: 0;
}

.close-icon {
  position: absolute;
  right: 0.3rem;
  top: 0.3rem;
  height: 40px;
  width: 40px;
}

.menu-container {
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 3;
  font-family: "Komikax", sans-serif;
  font-size: 16px;
  border-radius: 0 0 0 0;
  /*background-color: rgba(0, 0, 0, 0.8);*/
  background-image: url("./assets/background.png");
  color: #FFFFFF;
}

.statistics-block {
  background-color: #171717;
  border-radius: 12px;
  position: relative;
  padding: 1rem;
}

.statistics-wrapper {
  width: 80%;
}

.edit-profile-icon {
  position: absolute;
  top: 0.4rem;
  right: 0.4rem;
  width: 16px;
}

.edit-username-icon {
  width: 12px;
}

.username-text {
  margin-top: 0.2rem;
  margin-right: 1rem;
}

.pending-challenge-request-block {
  background-color: #000000;
  /*width: 55%;*/
  border-radius: 8px;
  position: relative;
  padding: 0 0.5rem;
  border: 1px solid #8D8D8DFF;
  margin: 0 0.3rem;
}


.pending-challenge-request-table th {
  vertical-align: middle;
  text-align: center;
}

.pending-challenge-request-table td {
  font-size: 0.8rem;
  vertical-align: middle;
  text-align: center;
}

.confirm-btn {
  width: 90%;
}

.coins-icon {
  width: 50px;
}

.profile-icon {
  width: 65%;
}

.game-logo {
  width: 150px;
}

.menu-option-container {
  position: absolute;
  top: 35%;
  left: 30px;
}

.menu-item-icon {
  width: 25px;
}

.menu-item-text-red {
  color: red;
}

.pop-up-title {
  width: 50%;
}

.pop-up-icon {
  width: 30%;
  margin: 0.4rem 0;
}

.arrow-icon {
  height: 60px;
  width: 60px;
}

.slide-burger-menu-leave-active, .slide-burger-menu-enter-active {
  transition: 1s;
  z-index: 2;
}

.slide-burger-menu-enter-from {
  transform: translateY(-100%);
}

.slide-burger-menu-leave-to {
  transform: translateY(-100%);
}
</style>
